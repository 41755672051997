import request from '@/utils/request'


// 查询用户分组列表
export function listUser(query) {
  return request({
    url: '/biz/userGrouping/list',
    method: 'get',
    params: query
  })
}

// 查询用户分组分页
export function pageUser(query) {
  return request({
    url: '/biz/userGrouping/getUserGroupingPage',
    method: 'get',
    params: query
  })
}

// 查询用户分组详细
export function getUser(data) {
  return request({
    url: '/biz/userGrouping/detail',
    method: 'get',
    params: data
  })
}

// 新增用户分组
export function addUser(data) {
  return request({
    url: '/biz/userGrouping/add',
    method: 'post',
    data: data
  })
}

// 修改用户分组
export function updateUser(data) {
  return request({
    url: '/biz/userGrouping/edit',
    method: 'post',
    data: data
  })
}

// 删除用户分组
export function delUser(data) {
  return request({
    url: '/biz/userGrouping/delete',
    method: 'post',
    data: data
  })
}
